import React, { useState, useEffect } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  IconButton,
  Box,
  Badge,
  Grid,Snackbar
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import Link from 'next/link';
import Image from 'next/image';
import airedify from '../../../../../apps/personal-portfolio/public/assets/logo/logo.svg';
import { useRouter } from 'next/router';
import ConstructionIcon from '@mui/icons-material/Construction';

// Importing appropriate MUI icons
import HomeIcon from '@mui/icons-material/Route'; // Roadmap
import BarChartIcon from '@mui/icons-material/BarChart'; // Tutorials
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'; // Interview QnA
import NoteIcon from '@mui/icons-material/Note'; // Notes
import ClassIcon from '@mui/icons-material/Terminal'; // My Classes
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium'; // My Certificates
import FlagIcon from '@mui/icons-material/Flag'; // Get Goals
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'; // My Calendar
import GroupsIcon from '@mui/icons-material/Groups'; // Community
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'; // Our Goals
import InfoIcon from '@mui/icons-material/Info'; // About Us
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const drawerWidth = 240; // Full width of the Drawer when expanded
const collapsedWidth = 60; // Width when the Drawer is collapsed

// Define your menu items
const menuItems = [
  { label: 'Roadmap', icon: <HomeIcon />, link: '/roadmaps', badge: 'New' },
  { label: 'Tutorials', icon: <BarChartIcon />, link: '/tutorial', count: 6 },
  {
    label: 'Interview',
    icon: <QuestionAnswerIcon />,
    link: '/interviewquiz',
  },
  { label: 'Notes', icon: <NoteIcon />, link: '/notes', count: 6 },
];

const menuItems2 = [
  { label: 'Playgrounds (IDE)', icon: <ClassIcon />, link: '/online-compiler' },
  {
    label: 'My Certificates',
    icon: <WorkspacePremiumIcon />,
    link: '/mycertificate',
  },
];

const myToolsItems = [
  { label: 'Get Goals', icon: <FlagIcon />, link: '/get-goals' },
  { label: 'My Calendar', icon: <CalendarMonthIcon />, link: '/my-calendar' },
];

const exploreItems = [
  { label: 'Community', icon: <GroupsIcon />, link: '/community' },
  { label: 'Our Goals', icon: <EmojiEventsIcon />, link: '/goals' },
  
];

const exploreItems2 = [
  
  { label: 'Who We Are?', icon: <InfoIcon />, link: 'https://intro.airedify.in/' }, // External link
];

const SideMenu = ({ onToggleCollapse }) => {
  const router = useRouter();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedItem, setSelectedItem] = useState('Roadmap');

  
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true); // Open the Snackbar on click
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return; // Prevent Snackbar from closing on clickaway
    setOpen(false);
  };

  const toggleCollapse = () => {
    const newState = !isCollapsed;
    setIsCollapsed(newState); // Update the collapse state
    onToggleCollapse(newState); // Notify parent component
  };

  const handleItemClick = (label) => {
    setSelectedItem(label); // Update the selected item
  };
  useEffect(() => {
    // Set collapsed state only for /tutorial page
    if (router.pathname === '/tutorial') {
      setIsCollapsed(true); // Collapsed by default on /tutorial
    } else {
      setIsCollapsed(false); // Uncollapsed on other pages
    }
  }, [router.pathname]);
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isCollapsed ? collapsedWidth : drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isCollapsed ? collapsedWidth : drawerWidth,
          boxSizing: 'border-box',
          overflowX: 'hidden',
          transition: 'width 0.3s',
        },
        display: { xs: 'none', sm: 'block' }, // Hide Drawer on mobile view
      }}
      open
    >
      <List
        style={{
          marginTop: '10px',
          paddingLeft: isCollapsed ? '8px' : '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: isCollapsed ? 'center' : 'flex-start',
        }}
      >
        {/* Container for arrow and logo */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: isCollapsed ? 'center' : 'space-between',
            width: '100%',
            padding: '0 10px',
            marginBottom: '10px',
          }}
        >
          {/* Arrow to toggle the sidebar */}
          <IconButton onClick={toggleCollapse}>
            {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>

          {/* Logo */}
          {!isCollapsed && (
            <Link href="/" className="logo">
              <Image
                src={airedify}
                alt="Logo"
                style={{
                  height: '55px',
                  transition: 'display 0.3s',
                }}
              />
            </Link>
          )}
        </Box>

        {/* Main Menu Items */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: isCollapsed
                ? 'repeat(1, 1fr)'
                : 'repeat(2, 1fr)',
              gap: '12px',
              maxWidth: isCollapsed ? '80px' : '320px',

              margin: '0 auto',
            }}
          >
            {menuItems.map((item, index) => {
              const isSelected = router.pathname === item.link;

              return (
                <Link href={item.link} passHref key={index}>
                  <Box
                    component="a"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: isSelected ? '#C8FFB4' : '#F5FFF1', // Background color based on selection
                      padding: '8px',
                      borderRadius: '10px',
                      boxShadow: isSelected
                        ? '0 1px 4px rgba(0, 0, 0, 0.1)'
                        : 'none',
                      position: 'relative',
                      textDecoration: 'none',
                      color: '#14A546',
                      marginLeft: isCollapsed ? '0px' : '5px',
                      '&:hover': { backgroundColor: '#C8FFB4' },
                      height: '60px',
                      width: isCollapsed ? '60px' : '100px',
                    }}
                  >
                    {/* Icon */}
                    <Box sx={{ fontSize: '1.8rem', color: '#37BB64' }}>
                      {item.icon}
                    </Box>

                    {/* Label - only show when not collapsed */}
                    {!isCollapsed && (
                      <Typography variant="body2" fontWeight="medium" mt={0.5}>
                        {item.label}
                      </Typography>
                    )}

                    {/* Count Badge - only show when not collapsed */}
                    {!isCollapsed && item.count && (
                      <Badge
                        badgeContent={item.count}
                        color="primary"
                        sx={{
                          position: 'absolute',
                          top: '6px',
                          right: '6px',
                          '& .MuiBadge-badge': {
                            backgroundColor: '#6c89f6',
                            color: '#fff',
                            fontSize: '0.6rem',
                            padding: '0 3px',
                            borderRadius: '50%',
                            height: '16px',
                            minWidth: '16px',
                          },
                        }}
                      />
                    )}

                    {/* "New" Badge - only show when not collapsed */}
                    {!isCollapsed && item.badge && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '6px',
                          right: '6px',
                          backgroundColor: '#6c89f6',
                          color: '#fff',
                          padding: '1px 4px',
                          fontSize: '0.6rem',
                          borderRadius: '8px',
                          lineHeight: '1',
                        }}
                      >
                        {item.badge}
                      </Box>
                    )}
                  </Box>
                </Link>
              );
            })}
          </Box>
        </Box>

        {/* Second Menu Section */}
        {menuItems2.map((item, index) => {
          const isSelected = router.pathname === item.link;

          return (
            <React.Fragment key={index}>
              <Link href={item.link} passHref>
                <ListItem
                  button
                  onClick={() => handleItemClick(item.label)}
                  sx={{
                    paddingLeft: isCollapsed ? '8px' : '16px',
                    '&:hover': { backgroundColor: '#F5F5F5' },
                    justifyContent: 'flex-start',
                    backgroundColor: isSelected ? '#6c89f6' : 'transparent',
                    color: isSelected ? '#fff' : '#5A5A5A',
                    borderRadius: '10px',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 'auto',
                      color: isSelected ? '#fff' : '#5A5A5A',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: isCollapsed ? 0 : '10px',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>

                  {!isCollapsed && (
                    <ListItemText
                      primary={item.label}
                      sx={{
                        fontWeight: 'medium',
                        flex: 1,
                        textAlign: 'left',
                      }}
                    />
                  )}
                </ListItem>
              </Link>
            </React.Fragment>
          );
        })}

        {/* My Tools Section */}
        <Divider sx={{ backgroundColor: '#E0E0E0', width: '100%' }} />
        {!isCollapsed && (
          <Typography
            sx={{
              margin: '10px 16px',
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#555',
            }}
          >
            MY TOOLS
          </Typography>
        )}
        {myToolsItems.map((item, index) => (
          <ListItem
            button
            key={index}
            onClick={handleClick} // Show Snackbar on click
            sx={{
              paddingLeft: isCollapsed ? '8px' : '16px',
              backgroundColor: '#FFF7E5',
              '&:hover': { backgroundColor: '#FFEBBD' },
              justifyContent: isCollapsed ? 'center' : 'flex-start',
              color: '#DC9A00',
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: '40px',
                color: '#F6AD00',
                '&:hover': { color: '#F6AD00' },
              }}
            >
              {item.icon}
            </ListItemIcon>
            {!isCollapsed && (
              <ListItemText
                primary={item.label}
                sx={{ fontWeight: 'medium', '&:hover': { color: '#F6AD00' } }}
              />
            )}
          </ListItem>
        ))}
        <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
       anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: '#FFF7E5',
            border: '1px solid #F6AD00',
            boxShadow: 'none',
            borderRadius: '8px',
            padding: '8px 16px',
            display: 'flex',
            alignItems: 'center',
          },
        }}
        message={
          <Box display="flex" alignItems="center">
            <WarningAmberIcon sx={{ color: '#F6AD00', marginRight: '8px' }} />
            <Box>
              <Typography variant="subtitle1" fontWeight="bold" color="#DC9A00">
                Under Development!
              </Typography>
              <Typography variant="body2" color="text.secondary">
                This feature is currently under development.
              </Typography>
            </Box>
          </Box>
        }
      />

        {/* Explore Section */}
        <Divider sx={{ backgroundColor: '#E0E0E0', width: '100%' }} />
        {!isCollapsed && (
          <Typography
            sx={{
              margin: '10px 16px',
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#555',
            }}
          >
            EXPLORE
          </Typography>
        )}
        {exploreItems.map((item, index) => (
          <Link href={item.link} passHref>
            <ListItem
              button
              onClick={() => handleItemClick(item.label)}
              key={index}
              sx={{
                paddingLeft: isCollapsed ? '8px' : '16px',
                '&:hover': { backgroundColor: '#f5f5f5' },
                justifyContent: isCollapsed ? 'center' : 'flex-start',
                color: '#5A5A5A',
              }}
            >
              <ListItemIcon sx={{ minWidth: '40px', color: '#5A5A5A' }}>
                {item.icon}
              </ListItemIcon>
              {!isCollapsed && (
                <ListItemText
                  primary={item.label}
                  sx={{ fontWeight: 'medium' }} // Set font weight to medium
                />
              )}
            </ListItem>
          </Link>
        ))}

        {/* Other Products Section */}
        <Divider sx={{ backgroundColor: '#E0E0E0', width: '100%' }} />
        {exploreItems2.map((item, index) => (
        // Use an anchor tag for the external link
        <a href={item.link} target={item.link.startsWith('http') ? '_self' : '_blank'} rel="noopener noreferrer" key={index}>
          <ListItem
            button
            onClick={() => handleItemClick(item.label)}
            sx={{
              paddingLeft: isCollapsed ? '8px' : '16px',
              '&:hover': { backgroundColor: '#f5f5f5' },
              justifyContent: isCollapsed ? 'center' : 'flex-start',
              color: '#5A5A5A',
            }}
          >
            <ListItemIcon sx={{ minWidth: '40px', color: '#5A5A5A' }}>
              {item.icon}
            </ListItemIcon>
            {!isCollapsed && (
              <ListItemText
                primary={item.label}
                sx={{ fontWeight: 'medium' }} // Set font weight to medium
              />
            )}
          </ListItem>
        </a>
      ))}
        {/* {!isCollapsed && (
          <Typography
            sx={{
              margin: '10px 16px',
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#555',
            }}
          >
            OTHER PRODUCTS
          </Typography>
        )} */}
      </List>
    </Drawer>
  );
};

export default SideMenu;
